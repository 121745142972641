import React from 'react';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import CancelRounded from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import EDIT_AUTOMATIC_EVALUATION_MAINTAINER from '../../../graphql/default/EditAutomaticEvaluationMaintainer';

const AutomaticEvaluationMaintainerEditActivateComponent = ({ row }) => {
  const [editAutomaticEvaluationMaintainer, { loading }] = useMutation(
    EDIT_AUTOMATIC_EVALUATION_MAINTAINER,
    {
      variables: { maintainerId: row.id },
    },
  );
  return (
    <Stack display="flex" alignItems="center">
      <Tooltip title={<Typography>{row.active ? 'Desactivar' : 'Activar'}</Typography>}>
        <LoadingIconButton onClick={editAutomaticEvaluationMaintainer} loading={loading}>
          {row.active ? <CheckCircleIcon color="success" /> : <CancelRounded color="error" />}
        </LoadingIconButton>
      </Tooltip>
    </Stack>
  );
};

AutomaticEvaluationMaintainerEditActivateComponent.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AutomaticEvaluationMaintainerEditActivateComponent;
