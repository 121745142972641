import * as Yup from 'yup';
import { useFormik } from 'formik';

const AddAutomaticDecisionMaintainerFormik = (onSubmitFunc = () => {}) => {
  const formik = useFormik({
    initialValues: {
      description: '',
      priority: '',
      order: '',
      triggerVariables: [
        {
          variable: '',
          variableLabel: '',
          operator: 'eq',
          comparedToVariable: '',
          comparedToVariableLabel: '',
        },
      ],
      decisionVariables: [
        {
          order: '1',
          variable: '',
          variableLabel: '',
          operator: 'eq',
          comparedToVariable: '',
          comparedToVariableLabel: '',
          resolution: 'Reject',
          resolutionComment: '',
        },
      ],
    },
    validationSchema: Yup.object({
      description: Yup.string().required('Campo obligatorio'),
      priority: Yup.number().typeError('Ingresa un número válido').required('Campo obligatorio').positive('Ingresa un número válido'),
      order: Yup.number().typeError('Ingresa un número válido').required('Campo obligatorio').positive('Ingresa un número válido'),
      triggerVariables: Yup.array().of(
        Yup.object({
          variable: Yup.string().required('Campo obligatorio'),
          variableLabel: Yup.string().required('Campo obligatorio'),
          operator: Yup.string().required('Campo obligatorio'),
          comparedToVariable: Yup.string().required('Campo obligatorio'),
          comparedToVariableLabel: Yup.string().required('Campo obligatorio'),
        }),
      ),
      decisionVariables: Yup.array().of(
        Yup.object({
          order: Yup.number().typeError('Ingresa un número válido').required('Campo obligatorio').positive('Ingresa un número válido'),
          variable: Yup.string().required('Campo obligatorio'),
          variableLabel: Yup.string().required('Campo obligatorio'),
          operator: Yup.string().required('Campo obligatorio'),
          comparedToVariable: Yup.string().required('Campo obligatorio'),
          comparedToVariableLabel: Yup.string().required('Campo obligatorio'),
          resolution: Yup.string().required('Campo obligatorio'),
          resolutionComment: Yup.string().required('Campo obligatorio'),
        }),
      ),
    }),
    onSubmit: onSubmitFunc,
    validateOnMount: true,
  });
  return formik;
};

export default AddAutomaticDecisionMaintainerFormik;
