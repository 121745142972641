import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '@fingo/lib/components/fields/SelectField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@fingo/lib/components/fields/TextField';

const AddAutomaticDecisionTriggerVariableComponent = ({ index }) => {
  const operatorItems = [
    { value: 'eq', label: 'Igual a' },
    { value: 'ge', label: 'Mayor o Igual a' },
    { value: 'le', label: 'Menor o Igual a' },
    { value: 'gt', label: 'Mayor que' },
    { value: 'lt', label: 'Menor que' },
    { value: 'ne', label: 'No es Igual a' },
  ];
  return (
    <>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" spacing={1}>
        <TextField
          name={`triggerVariables[${index}].variable`}
          label="Variable que se llamará por código (Ej: operation_amount)"
        />
        <SelectField
          name={`triggerVariables[${index}].operator`}
          inputLabel="Operador"
          items={operatorItems}
          fullWidth
        />
        <TextField
          name={`triggerVariables[${index}].comparedToVariable`}
          label="Variable la cual se va a comparar (Ej: 20000000)"
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          name={`triggerVariables[${index}].variableLabel`}
          label="Nombre de Variable en formato legible (Ej: Monto Operación Cliente)"
        />
        <TextField
          name={`triggerVariables[${index}].comparedToVariableLabel`}
          label="Nombre de Variable la cual se va a comparar en formato legible (Ej: $ 20.000.000)"
        />
      </Stack>
    </>
  );
};

AddAutomaticDecisionTriggerVariableComponent.propTypes = {
  index: PropTypes.number.isRequired,
};

export default AddAutomaticDecisionTriggerVariableComponent;
