import React from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import AutomaticDecisionTriggerVariablesColumns from '../../../constants/automatic-decision-trigger-variables.columns';
import GET_AUTOMATIC_EVALUATION_TRIGGER_VARIABLES from '../../../graphql/default/GetAutomaticEvaluationTriggerVariables';

const AutomaticEvaluationTriggerVariablesDialog = ({ maintainerId, maintainerDescription }) => {
  const [openAccept, toggleOpenAccept] = useBooleanState();
  const [loadTriggerVariables, { data, loading }] = useLazyQuery(
    GET_AUTOMATIC_EVALUATION_TRIGGER_VARIABLES,
    {
      variables: { maintainerId },
      onCompleted: () => {
        toggleOpenAccept();
      },
    },
  );
  return (
    <>
      <Stack display="flex" alignItems="center">
        <LoadingIconButton onClick={loadTriggerVariables} loading={loading}>
          <InsertDriveFile />
        </LoadingIconButton>
      </Stack>
      {openAccept && (
      <FingoDialog
        id="update-credit-buro-dialog"
        key="update-credit-buro-dialog"
        title="Variables de Contexto"
        subtitle={`Mantenedor ${maintainerDescription}`}
        open={openAccept}
        handleClose={toggleOpenAccept}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={{ height: 500 }}>
          <DataGrid
            rows={data?.getAutomaticEvaluationTriggerVariables ?? []}
            columns={AutomaticDecisionTriggerVariablesColumns}
            density="compact"
          />
        </Box>
      </FingoDialog>
      )}
    </>
  );
};

AutomaticEvaluationTriggerVariablesDialog.propTypes = {
  maintainerId: PropTypes.string.isRequired,
  maintainerDescription: PropTypes.string.isRequired,
};

export default AutomaticEvaluationTriggerVariablesDialog;
