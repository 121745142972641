import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '@fingo/lib/components/fields/SelectField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@fingo/lib/components/fields/TextField';

const AddAutomaticDecisionValidatorVariableComponent = ({ index }) => {
  const operatorItems = [
    { value: 'eq', label: 'Igual a' },
    { value: 'ge', label: 'Mayor o Igual a' },
    { value: 'le', label: 'Menor o Igual a' },
    { value: 'gt', label: 'Mayor que' },
    { value: 'lt', label: 'Menor que' },
    { value: 'ne', label: 'No es Igual a' },
  ];
  const resolutionItems = [
    { value: 'Reject', label: 'Rechazo' },
    { value: 'Manual', label: 'Manual' },
    { value: 'NoApprove', label: 'Sin Aprobación Automática' },
    { value: 'NoReject', label: 'Sin Rechazo Automático' },
    { value: 'Semiautomatic', label: 'Semiautomático' },
    { value: 'AcceptWithoutRestrictionClient', label: 'Aprobación sin Restricción Cliente' },
    { value: 'AcceptWithoutRestrictionDebtor', label: 'Aprobación sin Restricción Deudor' },
  ];
  return (
    <>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" spacing={1}>
        <TextField
          name={`decisionVariables[${index}].order`}
          label="Orden"
          disabled
        />
        <SelectField
          name={`decisionVariables[${index}].resolution`}
          inputLabel="Resolución"
          items={resolutionItems}
          fullWidth
        />
        <TextField
          name={`decisionVariables[${index}].resolutionComment`}
          label="Comentario de Resolución"
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          name={`decisionVariables[${index}].variable`}
          label="Variable que se llamará por código (Ej: operation_amount)"
        />
        <SelectField
          name={`decisionVariables[${index}].operator`}
          inputLabel="Operador"
          items={operatorItems}
          fullWidth
        />
        <TextField
          name={`decisionVariables[${index}].comparedToVariable`}
          label="Variable la cual se va a comparar (Ej: 20000000)"
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          name={`decisionVariables[${index}].variableLabel`}
          label="Nombre de Variable en formato legible (Ej: Monto Operación Cliente)"
        />
        <TextField
          name={`decisionVariables[${index}].comparedToVariableLabel`}
          label="Nombre de Variable la cual se va a comparar en formato legible (Ej: $ 20.000.000)"
        />
      </Stack>
    </>
  );
};

AddAutomaticDecisionValidatorVariableComponent.propTypes = {
  index: PropTypes.number.isRequired,
};

export default AddAutomaticDecisionValidatorVariableComponent;
