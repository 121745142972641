import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const AutomaticDecisionVariableHeaderComponent = ({
  title, subtitle, deleteVariableFunc, addVariableFunc, disabled,
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Stack direction="column" spacing={0.4}>
      <Typography variant="small_2">{title}</Typography>
      <Typography variant="small_1">{subtitle}</Typography>
    </Stack>
    <Stack direction="row">
      <IconButton
        onClick={deleteVariableFunc}
        disabled={disabled}
      >
        <RemoveCircleIcon color={disabled ? 'disabled' : 'error'} />
      </IconButton>
      <IconButton
        onClick={addVariableFunc}
      >
        <AddCircleIcon color="success" />
      </IconButton>
    </Stack>
  </Stack>
);

AutomaticDecisionVariableHeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  deleteVariableFunc: PropTypes.func.isRequired,
  addVariableFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AutomaticDecisionVariableHeaderComponent;
