import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import Typography from '@mui/material/Typography';
import AutomaticDecisionManagerColumns from '../../constants/automatic-decision-manager-columns';
import GET_AUTOMATIC_EVALUATION_MAINTAINERS from '../../graphql/default/GetAutomaticEvaluationMaintainers';
import AddAutomaticDecisionManagerDialog from './dialogs/AddAutomaticDecisionManagerDialog/AddAutomaticDecisionManagerDialog';

const AutomaticDecisionManager = () => (
  <FingoMainView
    type="automatic-decision-manager"
    query={GET_AUTOMATIC_EVALUATION_MAINTAINERS}
    queryCustomVariables={{
      orderBy: '-priority,order',
    }}
    slots={{
      header: PaperHeader,
      table: FingoTable,
      actions: AddAutomaticDecisionManagerDialog,
    }}
    slotProps={{
      header: {
        viewTitle: 'Mantenedor de Variables de Decisión Automática',
        finder: {
          searchPlaceHolder: 'Buscar',
        },
      },
      table: {
        includeHeaders: ['priority', 'order', 'description', 'active', 'triggerVariables', 'decisionVariables'],
        columns: AutomaticDecisionManagerColumns,
        checkboxSelection: false,
        isRowSelectable: () => true,
        collapsible: true,
        isRowCollapsible: () => false,
        disableSelectionOnClick: true,
        noRowsMessage: () => <Typography>No hay Mantenedor de Variables</Typography>,
        rowsPerPageOptions: [10, 15],
      },
    }}
  />
);

export default AutomaticDecisionManager;
