import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import TextField from '@fingo/lib/components/fields/TextField';
import { FormikProvider, FieldArray } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from '@apollo/client';
import AddAutomaticDecisionTriggerVariableComponent from './AddAutomaticDecisionTriggerVariableComponent';
import AddAutomaticDecisionValidatorVariableComponent from './AddAutomaticDecisionValidatorVariableComponent';
import CREATE_AUTOMATIC_EVALUATION_MAINTAINER from '../../../../graphql/default/CreateAutomaticEvaluationManager';
import GET_AUTOMATIC_EVALUATION_MAINTAINERS from '../../../../graphql/default/GetAutomaticEvaluationMaintainers';
import AddAutomaticDecisionMaintainerFormik from '../../hooks/AddAutomaticDecisionMaintainerFormik';
import AutomaticDecisionVariableHeaderComponent from './AutomaticDecisionVariableHeaderComponent';

const AddAutomaticDecisionManagerDialog = () => {
  const [openAccept, toggleOpenAccept] = useBooleanState();
  const formik = AddAutomaticDecisionMaintainerFormik();
  const addTriggerVariable = () => {
    const newVariable = {
      variable: '',
      variableLabel: '',
      operator: 'eq',
      comparedToVariable: '',
      comparedToVariableLabel: '',
    };
    formik.setFieldValue('triggerVariables', [...formik.values.triggerVariables, newVariable]);
  };
  const deleteTriggerVariable = () => {
    formik.setFieldValue('triggerVariables', formik.values.triggerVariables.slice(0, -1));
  };

  const addDecisionVariable = () => {
    const newVariable = {
      order: formik.values.decisionVariables.length + 1,
      variable: '',
      variableLabel: '',
      operator: 'eq',
      comparedToVariable: '',
      comparedToVariableLabel: '',
      resolution: 'Reject',
      resolutionComment: '',
    };
    formik.setFieldValue('decisionVariables', [...formik.values.decisionVariables, newVariable]);
  };
  const deleteDecisionVariable = () => {
    formik.setFieldValue('decisionVariables', formik.values.decisionVariables.slice(0, -1));
  };
  const [createMaintainer, { loading }] = useMutation(CREATE_AUTOMATIC_EVALUATION_MAINTAINER, {
    variables: formik.values,
    refetchQueries: [GET_AUTOMATIC_EVALUATION_MAINTAINERS],
    onCompleted: () => {
      toggleOpenAccept();
      formik.resetForm();
      formik.validateForm();
    },
  });
  return (
    <>
      <Stack display="flex" alignItems="center">
        <Button variant="contained" size="small" onClick={toggleOpenAccept}>
          Agregar Contexto
        </Button>
      </Stack>
      {openAccept && (
      <FingoDialog
        id="update-credit-buro-dialog"
        key="update-credit-buro-dialog"
        title="Agregar Contexto de Variables de Decisión"
        open={openAccept}
        handleClose={toggleOpenAccept}
        maxWidth="md"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disabled={!formik.isValid}
            onClick={createMaintainer}
            loading={loading}
          >
            Agregar Contexto de Variables
          </LoadingButton>
        )}
      >
        <Stack direction="column">
          <FormikProvider value={formik}>
            <form id="decision-manager-form">
              <TextField name="description" label="Descripción" />
              <Stack direction="row" spacing={1}>
                <TextField name="priority" label="Prioridad (Los contextos con prioridad más alta se revisarán primero)" />
                <TextField name="order" label="Orden (Dada una misma prioridad, los contextos con menor orden se revisarán primero)" />
              </Stack>
              <AutomaticDecisionVariableHeaderComponent
                title="Reglas del contexto"
                subtitle="Cuando se gatillen todas las reglas de contexto definidas, se evaluarán las variables de decisión"
                deleteVariableFunc={deleteTriggerVariable}
                addVariableFunc={addTriggerVariable}
                disabled={formik.values.triggerVariables.length === 1}
              />
              <FieldArray
                name="triggerVariables"
                render={() => (
                  <div>
                    {formik.values.triggerVariables.map((_, index) => (
                      <AddAutomaticDecisionTriggerVariableComponent index={index} />
                    ))}
                  </div>
                )}
              />
              <AutomaticDecisionVariableHeaderComponent
                title="Variables de Decisión"
                subtitle="Si alguna variable de decisión se gatilla, se ejecutará la resolución definida"
                deleteVariableFunc={deleteDecisionVariable}
                addVariableFunc={addDecisionVariable}
                disabled={formik.values.decisionVariables.length === 1}
              />
              <FieldArray
                name="decisionVariables"
                render={() => (
                  <div>
                    {formik.values.decisionVariables.map((_, index) => (
                      <AddAutomaticDecisionValidatorVariableComponent index={index} />
                    ))}
                  </div>
                )}
              />
            </form>
          </FormikProvider>
        </Stack>
      </FingoDialog>
      )}
    </>
  );
};

export default AddAutomaticDecisionManagerDialog;
