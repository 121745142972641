import React from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import AutomaticEvaluationMaintainerVariablesColumns from '../../../constants/automatic-evaluation-maintainer-variables-columns';
import GET_AUTOMATIC_EVALUATION_MAINTAINER_VARIABLES from '../../../graphql/default/GetAutomaticEvaluationMaintainerVariables';

const AutomaticEvaluationMaintainerVariablesDialog = ({ maintainerId }) => {
  const [openAccept, toggleOpenAccept] = useBooleanState();
  const [loadTriggerVariables, { data, loading }] = useLazyQuery(
    GET_AUTOMATIC_EVALUATION_MAINTAINER_VARIABLES,
    {
      variables: { maintainerId },
      onCompleted: () => {
        toggleOpenAccept();
      },
    },
  );
  const rows = data?.getAutomaticEvaluationMaintainerVariables?.map((row) => {
    const { id, variable: { ...restVariable } = {}, ...restRow } = row;
    return { id, ...restRow, ...restVariable };
  });
  return (
    <>
      <Stack display="flex" alignItems="center">
        <LoadingIconButton onClick={loadTriggerVariables} loading={loading}>
          <ArrowCircleRightIcon />
        </LoadingIconButton>
      </Stack>
      {openAccept && (
      <FingoDialog
        id="update-credit-buro-dialog"
        key="update-credit-buro-dialog"
        title="Variables de Decisión"
        open={openAccept}
        handleClose={toggleOpenAccept}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={{ height: 500 }}>
          <DataGrid
            rows={rows ?? []}
            columns={AutomaticEvaluationMaintainerVariablesColumns}
            density="compact"
          />
        </Box>
      </FingoDialog>
      )}
    </>
  );
};

AutomaticEvaluationMaintainerVariablesDialog.propTypes = {
  maintainerId: PropTypes.string.isRequired,
};

export default AutomaticEvaluationMaintainerVariablesDialog;
